import { Button, Input, Modal, Tooltip } from "antd";
import * as Styles from "./styles";
import { useEffect, useState } from "react";
import { ReactComponent as PickupIcon } from "./icons/pickup.svg";
import { ReactComponent as ImageIcon } from "./icons/product.svg";
import { ReactComponent as ProductIcon } from "./icons/product.svg";
import { ReactComponent as ProductIcon2 } from "./icons/product2.svg";
import { ReactComponent as ProductIcon3 } from "./icons/product3.svg";
import { ReactComponent as ProductIcon4 } from "./icons/product4.svg";
import { ReactComponent as ProductIcon5 } from "./icons/product5.svg";
import { ReactComponent as ProductIcon6 } from "./icons/product6.svg";
import { ReactComponent as PinkIcon } from "../icons/pink-icon.svg";
import { ReactComponent as GreenIcon } from "../icons/green-icon.svg";
import { ReactComponent as DotIcon } from "../icons/yellow-icon.svg";
import { ReactComponent as LineWhiteIcon } from "./icons/lineWhite.svg";
import { ReactComponent as PickupWhiteIcon } from "./icons/pickupWhite.svg";
import { ReactComponent as ScanIcon } from "./icons/scan.svg";
import { ReactComponent as InfoIcon } from "../icons/info-icon.svg";
import { ReactComponent as InfoIconWhite } from "../icons/info-icon-white.svg";
import { ReactComponent as HoldInfoIcon } from "./icons/hold-info.svg";
import { ReactComponent as ProgressInfoIcon } from "./icons/progress-info.svg";
import { ReactComponent as TickIcon } from "../icons/tick-icon.svg";
import React from 'react';
import { ReactComponent as LineIcon } from "./icons/line.svg";
import { PickItem } from "../../../../common/models/PickItem";
import { formatDistance } from "date-fns";
import axios from "../../../../common/api/axios";
import { message } from "antd";
import { ReactComponent as Toast } from "../../../../assets/icons/toast-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import OkPopup from "../ok-popup/OkPopup";
import { ReactComponent as ErrorIcon } from "./icons/error.svg";
import { ReactComponent as LoadIcon } from "./icons/loader.svg";

export interface OrderDetailsProps { pickItemsData?: any, pickStationsFirstData?: any, pickStationsSecondData?: any }

export default function DropBinDetails({ pickItemsData, pickStationsFirstData, pickStationsSecondData }: OrderDetailsProps) {
  const [isRestartPopup, setIsRestartPopup] = useState(false);
  const [isFlagPopup, setIsFlagPopup] = useState(false);
  const [qCount, setQCount] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);
  const [okayLoading, setOkayLoading] = useState(false);
  const [doneDisable, setDoneDisable] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const navigate = useNavigate();
  const [pickItems, setPickItems] = useState([]);
  const [orderItems, setOrderItems] = useState<any>([]);
  const [binId, setBinId] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [itemsData, setItemsData] = useState<any>([]);
  const [allQty, setAllQty] = useState(0);
  const [station, setStation] = useState(0);
  const [isOkPopup, setIsOkPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [state, setState] = useState("");
  const totalOrderCount = orderItems.reduce(
    (total: any, currentValue: any) =>
      (total = total + currentValue.replenishTask.quantity),
    0
  );
  useEffect(() => {
    setPickItems(pickItemsData);
  }, [pickItemsData])
  //const firstRow = ["11,4,1", "9,4,1", "8,4,1"];
  const firstRow = pickStationsFirstData;
  const secondRow = pickStationsSecondData;
  useEffect(() => {
    if (pickItems?.length !== 0 && pickItemsData.length !== orderItems?.length) {
      const new_overAllData = pickItemsData.map((val: any) => {
        if (val.replenishTask.from) {
          const orderPlace = Object.values(val.replenishTask.from);
          const joinedItem = orderPlace.join(",");
          return {
            ...val,
            position: joinedItem,
            orderColor: "default",
            selectedQty: "00",
            loaderStatus: false,
            completedStatus: false,
            okLoader: false,
          };
        } else {
          return <></>;
        }
      });
      setItemsData(new_overAllData);
      setOrderItems(new_overAllData);
      setSelectedOrder([]);
    }
  });

  const onHandleDoneButton = () => {
    setDoneLoading(true);
    //setTimeout(() => {
    setDoneLoading(false);
    messageApi.open({
      content: "Top-up task Complete !",
      icon: <Toast />,
      className: "order",
      style: {
        marginTop: "85vh",
      },
    });
    //}, 2000);
    //setTimeout(() => {
    navigate("/material-topup");
    //}, 1500);
  };

  // POST Pick Items for order fulfillment
  const getPickItemsData = async (id: string) => {
    setDoneLoading(true);
    setTimeout(() => {
      navigate("/material-popup");
    }, 2000);
    /* try {
      setDoneLoading(true);
      const res = await axios.put(`/PickStation/signalPick/${id}`, null, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setDoneLoading(false);
        setDoneDisable(true);
        noTaskToastMessage();
        setTimeout(() => {
          navigate("/order-id");
        }, 2000);
      }
    } catch (error: any) {
      setDoneLoading(true);
      setTimeout(() => {
        setDoneLoading(false);
      }, 1000);
      doneErrorToastMessage();
    } */
  };

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  const onHandleOkItem = async (id: string) => {
    setItemsData((current: any) =>
      itemsData.map((obj: any, key: any) => {
        if (obj.id === id) {
          return {
            ...obj,
            okLoader: true,
          };
        }
        return obj;
      })
    );
    //setOkayLoading(true);
    try {
      const res = await axios.put(
        `/PickStation/updateReplenishTaskQuantityAndComplete/${id}/${qCount}`,
        null,
        {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        }
      );
      if (res.status === 200) {
        messageApi.open({
          content: "Item Completed !",
          icon: <Toast />,
          className: "order",
          style: {
            marginTop: "85vh",
          },
        });
        setDoneDisable(false);
        setAllQty((prevState) => prevState + qCount);
        let sliceData = itemsData;
        await sliceData.splice(sliceData.findIndex((obj: any) => obj.id === id), 1);
        setItemsData(sliceData)
        /* setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                orderColor: "completed",
                completedStatus: true,
              };
            }
            return obj;
          })
        ); */
        setSelectedOrder([]);

        setSelectedTaskId("");
        setSelectedDate("");
        setOkayLoading(false);
      }
    } catch (error: any) {
      setErrorMessage(error.response.data);
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === id) {
            return {
              ...obj,
              okLoader: false,
            };
          }
          return obj;
        })
      );
      setIsOkPopup(true);
      setOkayLoading(false);
    }
  };

  const isOpenOkPopup = (taskId: string) => {
    onHandleOkItem(taskId);
  };

  const onHandleRestart = () => {
    window.location.reload();
  };

  const isCloseOkPopup = () => {
    //onHandleOkItem(selectedOrder.id);
    setIsOkPopup(false);
  };
  const isCancelOkPopup = () => {
    setIsOkPopup(false);
  };

  const onExtractSku = async (skuId: string, qty: number, id: string) => {
    try {
      const res = await axios.get(`/MasterData/sku/${skuId}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        let cntVal: any = qty < 10 ? ("0" + `${qty}`).slice(-2) : qty;

        setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                orderColor: "completed",
                selectedQty: cntVal,
                loaderStatus: false,
                image: res?.data?.imageUri,
              };
            }
            return obj;
          })
        );
        setSelectedOrder((current: any) => {
          return {
            ...res.data,
            quantity: qty,
            selectedQty: cntVal,
            id: id,
          };
        });
        setQCount(qty);
        // setAllQty((prevState) => prevState + qty);
      }
    } catch (error: any) {
      messageApi.open({
        type: "error",
        content: `Server Error !`,
        icon: <ErrorIcon />,
        className: "error",
        style: {
          marginTop: "85vh",
        },
      });
    }
  };

  const onHandleOrderItem = (orderItem: any, selectedKey: number) => {
    setStation(orderItem[0].position);
    setSelectedOrder(orderItem[0]);
    setBinId(orderItem[0].replenishTask.binId);

    let newOrderQuantity = [];
    newOrderQuantity[orderItem[0].replenishTask.skuId] = 1;
    setQCount(0);
    setItemsData((current: any) =>
      itemsData.map((obj: any, key: any) => {
        if (obj.id === orderItem[0].id && obj.orderColor !== "completed") {
          return {
            ...obj,
            orderColor: "progress",
          };
        } else {
          if (obj.orderColor === "progress" && obj.selectedQty > 0) {
            return {
              ...obj,
              orderColor: "hold",
            };
          } else {
            return {
              ...obj,
              orderColor:
                obj.orderColor === "" || obj.orderColor === "progress"
                  ? ""
                  : obj.orderColor,
            };
          }
        }
      })
    );
    onExtractSku(
      orderItem[0].replenishTask.binSkus[0].skuId,
      orderItem[0].replenishTask.binSkus[0].quantity,
      orderItem[0].id
    );
    setSelectedTaskId(orderItem[0].id);
    setSelectedDate(orderItem[0].creationDate);
  };

  const addQty = (qCount: number) => {
    setQCount(qCount);
    //setAllQty((prevState) => prevState + 1);
    let cntVal: any = qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;

    setSelectedOrder((current: any) => {
      return {
        ...current,
        selectedQty: cntVal,
      };
    });
    if (qCount === selectedOrder?.quantity) {
      setState("completed");

      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              orderColor: "completed",
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    } else {
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.replenishTask.binSkus[0].skuId === selectedOrder?.skuId) {
            return {
              ...obj,
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    }
  };
  const removeQty = (qCount: number) => {
    //setAllQty((prevState) => prevState - 1);
    setQCount(qCount);

    let cntVal = qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;
    setSelectedOrder((current: any) => {
      return {
        ...current,
        selectedQty: cntVal,
      };
    });

    if (qCount < selectedOrder?.quantity) {
      setState("completed");
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              orderColor: "progress",
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    } else {
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    }
  };

  return (
    <>
      <Styles.Container className="order-detail">
        <>
          <Styles.TaskBar>
            <Styles.TaskBarInner>
              {selectedTaskId !== "" && (
                <Styles.Item>
                  <span>Task ID:</span>
                  <span>{selectedTaskId}</span>
                </Styles.Item>
              )}
            </Styles.TaskBarInner>
            <Styles.TaskBarInner>
              <Styles.Item className="timestamp">
                {selectedDate !== "" && (
                  <span>Placed @ {dateTimeFormat(selectedDate)} Ago</span>
                )}
              </Styles.Item>
              <Styles.ItemText countReached={false}>
                <span>Items : </span>
                <span>
                  {allQty < 10 ? ("0" + `${allQty}`).slice(-2) : allQty} /
                  {totalOrderCount < 10
                    ? ("0" + `${totalOrderCount}`).slice(-2)
                    : totalOrderCount}
                </span>
              </Styles.ItemText>
            </Styles.TaskBarInner>
          </Styles.TaskBar>
          <Styles.Content>
            {selectedOrder?.length === 0 ? (
              <Styles.OrderCard className="empty">
                <Styles.Product>
                  Please, Select <br /> the Product Location
                </Styles.Product>
              </Styles.OrderCard>
            ) : (
              <Styles.OrderCard
                disable={qCount === selectedOrder?.quantity}
                increment={qCount > 0}
              >
                <Styles.ImgContainer className="left">
                  {selectedOrder?.imageUri && selectedOrder?.imageUri !== "" ? (
                    <img src={selectedOrder?.imageUri} alt="product image" />
                  ) : (
                    <ImageIcon />
                  )}
                </Styles.ImgContainer>
                <Styles.IDContainer>
                  <Styles.Labels>Item Name:</Styles.Labels>
                  <Tooltip
                    title={
                      selectedOrder?.productName &&
                        selectedOrder?.productName !== ""
                        ? selectedOrder?.productName
                        : ""
                    }
                  >
                    <Styles.ItemName className="description">
                      {selectedOrder?.productName &&
                        selectedOrder?.productName !== ""
                        ? selectedOrder?.productName
                        : ""}
                    </Styles.ItemName>
                  </Tooltip>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.Labels>Description:</Styles.Labels>
                  <Tooltip title={selectedOrder?.description}>
                    <Styles.ItemName className="description">
                      {selectedOrder?.description}
                    </Styles.ItemName>
                  </Tooltip>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.QContainer>
                    <div>
                      <Styles.Labels>Qty Count</Styles.Labels>
                      <Styles.ItemName className="count">
                        {selectedOrder?.selectedQty}
                      </Styles.ItemName>
                    </div>
                    <div>
                      <Styles.Labels>Qty Needed</Styles.Labels>
                      <Styles.ItemName className="need">
                        {selectedOrder?.quantity !== undefined &&
                          selectedOrder?.quantity < 10
                          ? ("0" + `${selectedOrder?.quantity}`).slice(-2)
                          : selectedOrder?.quantity}
                      </Styles.ItemName>
                    </div>
                    <div>
                      <Styles.Labels>Exp Date</Styles.Labels>
                      <Styles.ItemName className="exp">-NA-</Styles.ItemName>
                    </div>
                  </Styles.QContainer>
                </Styles.IDContainer>

                <Styles.IDContainer>
                  <Styles.Labels>SKU ID:</Styles.Labels>
                  <Styles.ItemName>{selectedOrder?.skuId}</Styles.ItemName>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Button
                    className="zero-btn"
                    disabled={selectedOrder?.selectedQty == 0}
                    onClick={() => removeQty(qCount - qCount)}
                  >
                    Make Qty as Zero
                  </Button>
                </Styles.IDContainer>
                <Styles.LeftButtonContainer>
                  <Button
                    className="dec-btn"
                    disabled={selectedOrder?.selectedQty == 0}
                    onClick={() => removeQty(qCount - 1)}
                  >
                    -
                  </Button>
                  <Button
                    className="inc-btn"
                    onClick={() => addQty(qCount + 1)}
                    disabled={qCount === selectedOrder?.quantity}
                  >
                    +
                  </Button>
                </Styles.LeftButtonContainer>
              </Styles.OrderCard>
            )}

            <Styles.OrderDetail doneDisabled={false}>
              <div>
                <Styles.OrderContainer>
                  <Styles.HeadContainer>
                    <Styles.Head>Drop Bins</Styles.Head>
                    <Styles.Info>
                      <Styles.InfoText>
                        <Styles.Dot className="available" /> Available
                      </Styles.InfoText>
                      <Styles.InfoText>
                        <Styles.Dot className="unavailable" /> Unavailable
                      </Styles.InfoText>
                      <Styles.InfoText>
                        <Styles.Dot className="selected" /> In Progress
                      </Styles.InfoText>
                      {/* <Styles.InfoText>
                      <Styles.Dot className="pending" /> Pending
                    </Styles.InfoText> */}
                      <Styles.InfoText>
                        <Styles.Dot className="completed" /> Completed
                      </Styles.InfoText>
                    </Styles.Info>
                  </Styles.HeadContainer>
                  <Styles.PickupContainer state={firstRow.length}>
                    <Styles.GridRow columns={firstRow.length}>
                      {firstRow.map((val: any, key: any) => {
                        const Index = itemsData.filter(
                          (item: any) => item.position === val
                        );
                        // const orderPlace = Object.values(
                        //   orderItems.inwardTask.from
                        // );
                        // const joinedItem = orderPlace.join(",");

                        return (
                          <React.Fragment key={`first-row-${key}`}>
                            {Index.length > 0 ? (

                              <Styles.Pickup
                                state={Index[0].orderColor}
                                onClick={() =>
                                  Index[0].completedStatus === false &&
                                  selectedOrder.length === 0 &&
                                  onHandleOrderItem(Index, key)
                                }
                                //okDisable={Index[0].completedStatus}
                                okDisable={
                                  Index[0].selectedQty >= 0 &&
                                    Index[0].completedStatus === false
                                    ? false
                                    : true
                                }
                                disable={
                                  Index[0].completedStatus === false &&
                                  selectedOrder.length === 0
                                }
                              >
                                <div className="main">
                                  <div className="second">
                                    <Styles.PickImage>
                                      {Index[0].image ? (
                                        <img src={Index[0].image} alt="box image" />
                                      ) : (
                                        <ProductIcon />
                                      )}
                                    </Styles.PickImage>
                                    <Styles.PickIcon color={Index[0].orderColor}>
                                      <Styles.Qty state={Index[0].orderColor}>
                                        {Index[0].selectedQty}/{" "}
                                        {Index[0].replenishTask.quantity < 10
                                          ? (
                                            "0" +
                                            `${Index[0].replenishTask.quantity}`
                                          ).slice(-2)
                                          : Index[0].replenishTask.quantity}
                                      </Styles.Qty>
                                      {Index[0].orderColor === "completed" ? (
                                        Index[0].completedStatus === true ? (
                                          <TickIcon />
                                        ) : (
                                          <InfoIconWhite />
                                        )
                                      ) : Index[0].orderColor === "hold" ? (
                                        <HoldInfoIcon />
                                      ) : Index[0].orderColor === "progress" ? (
                                        <ProgressInfoIcon />
                                      ) : (
                                        <InfoIcon />
                                      )}

                                      <Button
                                        className="ok-btn"
                                        onClick={() => isOpenOkPopup(Index[0]?.id)}
                                        //disabled={Index[0].completedStatus}
                                        disabled={
                                          Index[0].id == selectedOrder.id &&
                                            Index[0].selectedQty >= 0 &&
                                            Index[0].completedStatus === false
                                            ? false
                                            : true
                                        }
                                      >
                                        {Index[0].okLoader === true ? (
                                          <LoadIcon />
                                        ) : (
                                          "OK"
                                        )}
                                      </Button>
                                    </Styles.PickIcon>
                                  </div>
                                  <div>
                                    {/* <Styles.DropContainer> */}
                                    <Styles.DropSlot
                                      disable={
                                        Index[0].orderColor
                                      }
                                    >
                                      Slot - {Index[0].position !== ""
                                        ? Index[0].position
                                        : "- NA - "}

                                      <Styles.DropIcon>
                                        <PickupWhiteIcon />
                                      </Styles.DropIcon>
                                    </Styles.DropSlot>
                                    {/* </Styles.DropContainer> */}
                                  </div>
                                </div>
                              </Styles.Pickup>

                            ) : (
                              <Styles.Pickup ></Styles.Pickup>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Styles.GridRow>
                    <Styles.GridRow columns={secondRow.length}>
                      {secondRow.map((val: any, key: any) => {
                        const Index = itemsData.filter(
                          (item: any) => item.position === val
                        );
                        // const orderPlace = Object.values(
                        //   orderItems.inwardTask.from
                        // );
                        // const joinedItem = orderPlace.join(",");

                        return (
                          <React.Fragment key={`second-row-${key}`}>
                            {Index.length > 0 ? (

                              <Styles.Pickup
                                state={Index[0].orderColor}
                                onClick={() =>
                                  Index[0].completedStatus === false &&
                                  selectedOrder.length === 0 &&
                                  onHandleOrderItem(Index, key)
                                }
                                //okDisable={Index[0].completedStatus}
                                okDisable={
                                  Index[0].id == selectedOrder.id &&
                                    Index[0].selectedQty >= 0 &&
                                    Index[0].completedStatus === false
                                    ? false
                                    : true
                                }
                                disable={
                                  Index[0].completedStatus === false &&
                                  selectedOrder.length === 0
                                }
                              >
                                <div className="main">
                                  <div className="second">
                                    <Styles.PickImage>
                                      {Index[0].image ? (
                                        <img src={Index[0].image} alt="box image" />
                                      ) : (
                                        <ProductIcon />
                                      )}
                                    </Styles.PickImage>
                                    <Styles.PickIcon color={Index[0].orderColor}>
                                      <Styles.Qty state={Index[0].orderColor}>
                                        {Index[0].selectedQty}/{" "}
                                        {Index[0].replenishTask.quantity < 10
                                          ? (
                                            "0" +
                                            `${Index[0].replenishTask.quantity}`
                                          ).slice(-2)
                                          : Index[0].replenishTask.quantity}
                                      </Styles.Qty>
                                      {Index[0].orderColor === "completed" ? (
                                        Index[0].completedStatus === true ? (
                                          <TickIcon />
                                        ) : (
                                          <InfoIconWhite />
                                        )
                                      ) : Index[0].orderColor === "hold" ? (
                                        <HoldInfoIcon />
                                      ) : Index[0].orderColor === "progress" ? (
                                        <ProgressInfoIcon />
                                      ) : (
                                        <InfoIcon />
                                      )}

                                      <Button
                                        className="ok-btn"
                                        onClick={() => isOpenOkPopup(Index[0]?.id)}
                                        //disabled={Index[0].completedStatus}
                                        disabled={
                                          Index[0].id == selectedOrder.id &&
                                            Index[0].selectedQty >= 0 &&
                                            Index[0].completedStatus === false
                                            ? false
                                            : true
                                        }
                                      >
                                        {Index[0].okLoader === true ? (
                                          <LoadIcon />
                                        ) : (
                                          "OK"
                                        )}
                                      </Button>
                                    </Styles.PickIcon>
                                  </div>
                                  <div>
                                    {/* <Styles.DropContainer> */}
                                    <Styles.DropSlot
                                      disable={
                                        Index[0].orderColor
                                      }
                                    >
                                      Slot - {Index[0].position !== ""
                                        ? Index[0].position
                                        : "- NA - "}

                                      <Styles.DropIcon>
                                        <PickupWhiteIcon />
                                      </Styles.DropIcon>
                                    </Styles.DropSlot>
                                    {/* </Styles.DropContainer> */}
                                  </div>
                                </div>
                              </Styles.Pickup>

                            ) : (
                              <Styles.Pickup ></Styles.Pickup>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Styles.GridRow>
                  </Styles.PickupContainer>
                </Styles.OrderContainer>
                <Styles.OrderDivider />
                <Styles.OrderContainer>
                  <Styles.Head>Drop Bin Details</Styles.Head>
                  {selectedOrder?.length === 0 ?
                    <Styles.Text>Select Bin for Details</Styles.Text>
                    :
                    <>
                      <Styles.Text>Tote: {binId}</Styles.Text>
                      <Styles.Text> Pick Station: {station}</Styles.Text>
                    </>
                  }

                </Styles.OrderContainer>
              </div>
              <div>
                <Styles.OrderBtnContainer doneDisabled={doneDisable}>
                  <Button
                    className="done-btn"
                    onClick={() => onHandleDoneButton()}
                    loading={doneLoading}
                  >
                    {doneLoading === true ? "Loading..." : "Done"}
                  </Button>
                </Styles.OrderBtnContainer>
              </div>
            </Styles.OrderDetail>
          </Styles.Content>
          <OkPopup
            visible={isOkPopup}
            isCloseOkPopup={isCloseOkPopup}
            isRestart={onHandleRestart}
            errorMessage={errorMessage}
            isCancel={isCancelOkPopup}
          />
        </>
      </Styles.Container>
      <>{contextHolder}</>
    </>
  );
}
